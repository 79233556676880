<template>
  <div class="reseller-cart-quantity-editor">
    <div class="content">
      <!-- <table class="table">
        <thead>
          <tr>
            <th>Artikel</th>
            <th></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <small-license-card
                :license="purchasedLicenseData"
              />
            </td>
            <td>
              <b-field label="Größe">
                <b-input
                  v-model="seatCount"
                  type="number"
                  step="1"
                  min="0"
                />
              </b-field>
            </td>
            <td>
              <b-button
                v-if="removable"
                type="is-danger"
                icon-left="times"
                @click="$emit('remove')"
              />
            </td>
          </tr>
          <tr :class="{ 'disabled': !canPurchaseDVDs }">
            <td>
              <div class="media">
                <div class="media-left">
                  <figure class="image is-64x64 has-text-centered">
                    <img :src="dvdImage">
                  </figure>
                </div>
                <div class="media-content">
                  <h6>METACOM 9 Desktop - Installationsdatenträger (DVD) zur Lizenz inkl. Versand</h6>
                </div>
              </div>
            </td>
            <td>
              <b-input
                v-model="dvdCount"
                :disabled="!canPurchaseDVDs"
                type="number"
                step="1"
                min="0"
                placeholder="1"
              />
            </td>
            <td />
          </tr>
        </tbody>
      </table> -->
    </div>
    <b-message
      v-if="!canPurchaseDVDs"
      class=""
      type="is-warning"
    >
      Die Lieferung zusätzlicher Installationsdatenträger (DVD) ist nur
      innerhalb von Deutschland möglich. Für die Nutzung der Lizenz ist eine DVD
      jedoch nicht erforderlich.
    </b-message>

    <b-message v-if="licenseType === 'personal' && seatCount > 0">
      <div class="content">
        Sie bestellen folgende Produkte:
        <ul>
          <li v-if="dvdCount > 0">
            {{ dvdCount }} Persönliche Lizenzen mit DVD
          </li>
          <li v-if="seatCount - dvdCount > 0">
            {{ seatCount - dvdCount }} Persönliche Lizenzen ohne DVD
          </li>
        </ul>
      </div>
    </b-message>

    <b-message v-if="licenseType === 'pro' && seatCount > 1">
      <div class="content">
        Es kann jeweils nur eine Pro-Lizenz pro Bestellung erworben werden. Sie
        bestellen:
        <ul>
          <li>
            <span>1 Pro-Lizenz mit {{ seatCount }}
              {{ seatCount > 1 ? 'Lizenzplätzen' : 'Lizenzplatz' }}</span>
            <span v-if="dvdCount > 0">
              und {{ dvdCount }} DVD{{ dvdCount > 1 ? 's' : '' }}</span>
          </li>
        </ul>
      </div>
    </b-message>
  </div>
</template>

<script>
import licenseMixin from '@/mixins/license'
// import SmallLicenseCard from '@/components/general/SmallLicenseCard.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import dvdImage from '@/assets/images/license-images/m9_dvd.svg'

export default {
  components: {
    // SmallLicenseCard
  },
  mixins: [licenseMixin],
  props: {
    removable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dvdImage,
      query: ''
    }
  },
  computed: {
    ...mapState('assistant', ['licenseType']),
    ...mapGetters('assistant', ['purchasedLicenseData', 'canPurchaseDVDs']),
    seatCount: {
      get () {
        return this.$store.state.assistant.seatCount
      },
      set (value) {
        this.updateState({ key: 'seatCount', value })
      }
    },
    dvdCount: {
      get () {
        return this.$store.state.assistant.dvdCount
      },
      set (value) {
        this.updateState({ key: 'dvdCount', value })
      }
    },
    selectedCountry: {
      get () {
        return this.$store.state.assistant.billing.countryCode
      },
      set (value) {
        this.$store.state.assistant.billing.countryCode = value
      }
    }
  },
  methods: {
    ...mapMutations('assistant', ['updateState'])
  }
}
</script>
