var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"license-change-preview"},[_c('div',{staticClass:"field"},[(_vm.purchaseType === 'extension')?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ihre neue Lizenz:"}},[_c('small-license-card',{attrs:{"binding-state":false,"license":_vm.purchasedLicenseData}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ihre bisherige Lizenz wird erweitert:"}},[_c('ul',[_c('li',[_c('small-license-card',{attrs:{"binding-state":false,"license":_vm.authenticatedLicenses[0].license,"crossed":""}})],1)])])],1)]):(_vm.purchaseType === 'upgrade')?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ihre neue Lizenz:"}},[_c('small-license-card',{attrs:{"binding-state":false,"license":_vm.purchasedLicenseData}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":'Ihre bisherige' +
              (_vm.authenticatedLicenses.length > 1 ? 'n' : '') +
              ' Lizenz' +
              (_vm.authenticatedLicenses.length > 1 ? 'en werden' : ' wird') +
              ' ersetzt' +
              (_vm.authenticatedLicenses.length > 1 ? ' und zusammengeführt' : '') +
              ':'}},[_c('ul',_vm._l((_vm.authenticatedLicenses),function(d){return _c('li',{key:d.license._id,staticClass:"mb-4"},[_c('small-license-card',{attrs:{"binding-state":false,"license":d.license,"crossed":""}})],1)}),0)])],1)]):(_vm.purchaseType === 'new')?_c('div',{},[_c('b-field',{attrs:{"label":"Ihre neue Lizenz:"}},[_c('small-license-card',{attrs:{"binding-state":false,"license":_vm.purchasedLicenseData}})],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }