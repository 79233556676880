export default {
  methods: {
    getPurchaseTypeText (purchaseType) {
      switch (purchaseType) {
        case 'upgrade':
          return 'Update'
        case 'new':
          return 'Neukauf'
        case 'extension':
          return 'Erweiterung'
        case 'reseller':
          return 'Reseller'
        default:
          return purchaseType
      }
    },
    getPurchaseTypeClass (purchaseType) {
      switch (purchaseType) {
        case 'upgrade':
          return 'is-update'
        case 'new':
          return 'is-full'
        case 'extension':
          return 'is-extension'
        case 'reseller':
          return 'is-reseller'
        default:
          return purchaseType
      }
    }
  }
}
