export default {
  BE: { name: 'Belgien', key: 'BE', vat: 0.21 },
  BG: { name: 'Bulgarien', key: 'BG', vat: 0.2 },
  DK: { name: 'Dänemark', key: 'DK', vat: 0.25 },
  DE: { name: 'Deutschland', key: 'DE', vat: 0.19 },
  EE: { name: 'Estland', key: 'EE', vat: 0.2 },
  FI: { name: 'Finnland', key: 'FI', vat: 0.255 },
  FR: { name: 'Frankreich', key: 'FR', vat: 0.2 },
  GR: { name: 'Griechenland', key: 'GR', vat: 0.24 },
  IE: { name: 'Irland', key: 'IE', vat: 0.23 },
  IT: { name: 'Italien', key: 'IT', vat: 0.22 },
  HR: { name: 'Kroatien', key: 'HR', vat: 0.25 },
  LV: { name: 'Lettland', key: 'LV', vat: 0.21 },
  LT: { name: 'Litauen', key: 'LT', vat: 0.21 },
  LU: { name: 'Luxemburg', key: 'LU', vat: 0.17 },
  MT: { name: 'Malta', key: 'MT', vat: 0.18 },
  NL: { name: 'Niederlande', key: 'NL', vat: 0.21 },
  AT: { name: 'Österreich', key: 'AT', vat: 0.2 },
  PL: { name: 'Polen', key: 'PL', vat: 0.23 },
  PT: { name: 'Portugal', key: 'PT', vat: 0.23 },
  RO: { name: 'Rumänien', key: 'RO', vat: 0.19 },
  SE: { name: 'Schweden', key: 'SE', vat: 0.25 },
  CH: { name: 'Schweiz', key: 'CH', vat: 0.081 },
  LI: { name: 'Liechtenstein', key: 'LI', vat: 0.081 },
  SK: { name: 'Slowakei', key: 'SK', vat: 0.23 },
  SI: { name: 'Slowenien', key: 'SI', vat: 0.22 },
  ES: { name: 'Spanien', key: 'ES', vat: 0.21 },
  CZ: { name: 'Tschechien', key: 'CZ', vat: 0.21 },
  HU: { name: 'Ungarn', key: 'HU', vat: 0.27 },
  CY: { name: 'Zypern', key: 'CY', vat: 0.19 }
}
