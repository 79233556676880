<template>
  <div class="manual-order">
    <b-field label="In welches Land soll die Bestellung gehen?">
      <country-select v-model="selectedCountry" />
    </b-field>

    <reseller-cart class="mt-6" />

    <div class="content mt-6">
      <b-field
        v-if="purchasedLicenseData"
        label="Zusammenfassung des Kaufs:"
      >
        <cart-summary />
      </b-field>
    </div>

    <b-button
      type="is-primary"
      :disabled="!purchasedLicenseData"
      @click="$emit('submit')"
    >
      Zur Überprüfung
    </b-button>
  </div>
</template>

<script>
import licenseMixin from '@/mixins/license'
import CountrySelect from '@/components/general/CountrySelect'
import CartSummary from '@/components/customer/assistant/CartSummary.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import dvdImage from '@/assets/images/license-images/m9_dvd.svg'
import ResellerCart from '@/components/customer/assistant/ResellerCart.vue'

export default {
  components: {
    CountrySelect,
    CartSummary,
    ResellerCart
  },
  mixins: [licenseMixin],
  data () {
    return {
      dvdImage,
      query: ''
    }
  },
  computed: {
    ...mapState('assistant', ['licenseType']),
    ...mapGetters('assistant', ['purchasedLicenseData', 'canPurchaseDVDs']),
    selectedCountry: {
      get () {
        return this.$store.state.assistant.billing.countryCode
      },
      set (value) {
        this.$store.state.assistant.billing.countryCode = value

        if (!this.canPurchaseDVDs) {
          this.$store.commit('assistant/removeAllDVDs')
        }
      }
    }
  },
  mounted () {},
  methods: {
    ...mapMutations('assistant', ['updateState'])
  }
}
</script>
