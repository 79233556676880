<template>
  <div class="multi-license-login">
    <!-- Authenticated licenses -->
    <b-field
      v-if="authenticatedLicenses.length > 0"
      :label="
        authenticatedLicenses.length === 1
          ? `Diese Lizenz ${actionText}:`
          : `Diese Lizenzen ${actionText}:`
      "
    >
      <ul>
        <li
          v-for="license of authenticatedLicenses"
          :key="license.license._id"
        >
          <small-license-card
            removable
            :license="license.license"
            @remove="onRemoveLicense"
          />
        </li>
      </ul>
    </b-field>

    <!-- Add another license -->
    <b-field
      v-if="!authenticateAnotherLicense && canAuthenticateAnotherLicense"
      label="Zum Zusammenführen mehrerer Lizenzen:"
    >
      <b-button
        size="is-small"
        type="is-secondary"
        icon-left="plus"
        @click="onAuthenticateAnotherLicenseButtonClicked"
      >
        Bei weiterer Lizenz anmelden
      </b-button>
    </b-field>

    <b-message
      v-if="isLicenseVersionRejected"
      type="is-warning"
      title="Ungültige Lizenz-Version"
      class="mt-2 mb-4"
      :closable="false"
    >
      <p>
        Die gewählte Lizenz ist für eine ungültige METACOM Version:
        {{ getMetacomVersionName(rejectedLicenseVersion) }}. Bitte melden Sie
        sich mit einer
        {{
          restrictedVersions.map((v) => getMetacomVersionName(v)).join('oder ')
        }}
        Lizenz an.
      </p>
    </b-message>

    <b-message
      v-if="isLicenseClassRejected"
      type="is-warning"
      title="Ungültiger Lizenztyp"
      class="mt-2 mb-4"
      :closable="false"
    >
      <p>
        Das Erweitern von Lizenzen ist nur für Pro-Lizenzen (METACOM 9 Desktop)
        möglich.
      </p>
    </b-message>

    <!-- Authenticate with license -->
    <horizontal-license-login
      v-if="canAuthenticateAnotherLicense && authenticateAnotherLicense"
      ref="authenticationComponent"
      @authenticated="onLicenseAuthenticated"
    />

    <error-message
      :error="createError"
      title="Fehler beim Aktualisieren der Update-Session"
      @retry="onSubmit"
    />

    <b-button
      size="large"
      type="is-primary"
      style="margin-top: 20px"
      icon-left="check"
      expanded
      :disabled="authenticatedLicenses.length === 0"
      :loading="isLoadingCreate"
      @click="onSubmit"
    >
      Die ausgewählte{{ authenticatedLicenses.length > 1 ? 'n' : '' }} Lizenz{{
        authenticatedLicenses.length > 1 ? 'en' : ''
      }}
      {{ actionText }}
    </b-button>
  </div>
</template>

<script>
import HorizontalLicenseLogin from './HorizontalLicenseLogin'
import SmallLicenseCard from '@/components/general/SmallLicenseCard.vue'
import ErrorMessage from '@/components/general/ErrorMessage.vue'
import licenseMixin from '@/mixins/license'
import { mapState } from 'vuex'
export default {
  components: { HorizontalLicenseLogin, SmallLicenseCard, ErrorMessage },
  mixins: [licenseMixin],
  props: {
    limit: {
      type: Number,
      default: null
    },
    restrictedVersions: {
      type: Array,
      default: null
    },
    restrictedClasses: {
      type: Array,
      default: null
    },
    actionText: {
      type: String,
      default: 'updaten'
    }
  },
  data () {
    return {
      authenticateAnotherLicense: true,
      isLicenseVersionRejected: false,
      rejectedLicenseVersion: null,
      isLicenseClassRejected: false
    }
  },

  computed: {
    ...mapState('assistant', [
      'authenticatedLicenses',
      'createError',
      'isLoadingCreate'
    ]),
    canAuthenticateAnotherLicense () {
      return (
        this.limit == null || this.authenticatedLicenses.length < this.limit
      )
    }
  },

  methods: {
    onLicenseAuthenticated (data) {
      this.isLicenseVersionRejected = false
      this.isLicenseClassRejected = false
      this.rejectedLicenseVersion = null

      if (
        this.restrictedVersions &&
        this.restrictedVersions.length &&
        !this.restrictedVersions.includes(data.license.version)
      ) {
        this.isLicenseVersionRejected = true
        this.rejectedLicenseVersion = data.license.version
        this.$refs.authenticationComponent?.reset()
        return
      }

      if (
        this.restrictedClasses &&
        this.restrictedClasses.length &&
        !this.restrictedClasses.includes(data.license.properties.class)
      ) {
        this.isLicenseClassRejected = true
        this.$refs.authenticationComponent?.reset()
        return
      }
      this.$store.commit('assistant/addAuthenticatedLicense', {
        license: data.license,
        accessToken: data.authToken
      })
      this.authenticateAnotherLicense = false
    },
    onAuthenticateAnotherLicenseButtonClicked () {
      this.authenticateAnotherLicense = true
    },
    onRemoveLicense (license) {
      this.$store.commit('assistant/removeAuthenticatedLicense', license)

      if (this.authenticatedLicenses.length === 0) {
        this.authenticateAnotherLicense = true
      }
    },
    async onSubmit () {
      this.$emit('completed')
    }
  }
}
</script>
