<template>
  <div class="license-change-preview">
    <div class="field">
      <div
        v-if="purchaseType === 'extension'"
        class="columns"
      >
        <div class="column">
          <b-field label="Ihre neue Lizenz:">
            <small-license-card
              :binding-state="false"
              :license="purchasedLicenseData"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ihre bisherige Lizenz wird erweitert:">
            <ul>
              <li>
                <small-license-card
                  :binding-state="false"
                  :license="authenticatedLicenses[0].license"
                  crossed
                />
              </li>
            </ul>
          </b-field>
        </div>
      </div>
      <div
        v-else-if="purchaseType === 'upgrade'"
        class="columns"
      >
        <div class="column">
          <b-field label="Ihre neue Lizenz:">
            <small-license-card
              :binding-state="false"
              :license="purchasedLicenseData"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="
              'Ihre bisherige' +
                (authenticatedLicenses.length > 1 ? 'n' : '') +
                ' Lizenz' +
                (authenticatedLicenses.length > 1 ? 'en werden' : ' wird') +
                ' ersetzt' +
                (authenticatedLicenses.length > 1 ? ' und zusammengeführt' : '') +
                ':'
            "
          >
            <ul>
              <li
                v-for="d in authenticatedLicenses"
                :key="d.license._id"
                class="mb-4"
              >
                <small-license-card
                  :binding-state="false"
                  :license="d.license"
                  crossed
                />
              </li>
            </ul>
          </b-field>
        </div>
      </div>
      <div
        v-else-if="purchaseType === 'new'"
        class=""
      >
        <b-field label="Ihre neue Lizenz:">
          <small-license-card
            :binding-state="false"
            :license="purchasedLicenseData"
          />
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
import SmallLicenseCard from '@/components/general/SmallLicenseCard.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    SmallLicenseCard
  },
  computed: {
    ...mapState('assistant', ['purchaseType', 'authenticatedLicenses']),
    ...mapGetters('assistant', ['purchasedLicenseData', 'canPurchaseDVDS'])
  }
}
</script>
