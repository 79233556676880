<template>
  <div class="reseller-cart">
    <div class="buttons">
      <b-button
        icon-left="plus"
        @click="onAddResellerLicense('personal')"
      >
        Persönliche Lizenz
      </b-button>
      <b-button
        icon-left="plus"
        @click="onAddResellerLicense('pro')"
      >
        Pro-Lizenz
      </b-button>
    </div>

    <reseller-cart-item
      v-for="license of resellerLicenses"
      :key="license.uid"
      :license="license"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ResellerCartItem from './ResellerCartItem.vue'

export default {
  components: { ResellerCartItem },
  data () {
    return {
      licenseNo: 1
    }
  },
  computed: {
    ...mapState('assistant', ['resellerLicenses'])
  },
  methods: {
    onAddResellerLicense (licenseClass) {
      this.$store.commit('assistant/addResellerLicense', {
        class: licenseClass
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
    }
  }
}
</script>

<style lang="scss" scoped></style>
