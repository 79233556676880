import { render, staticRenderFns } from "./BackendUpgradeSessionOverview.vue?vue&type=template&id=ecd9a59c&lang=pug"
import script from "./BackendUpgradeSessionOverview.vue?vue&type=script&lang=js"
export * from "./BackendUpgradeSessionOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports