<template>
  <div class="reseller-cart-item p-3">
    <div class="is-flex">
      <div class="p-4">
        <figure>
          <img
            width="128"
            height="128"
            :src="getLicenseImageOfLicense(license)"
          >
        </figure>
      </div>
      <div class="is-flex-grow-1 p-1 is-flex-direction-column">
        <div>
          <h4 class="is-size-5 has-text-weight-bold">
            {{ getShortFormattedNameOfLicense(license) }}
          </h4>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1 p-1">
            <b-field label="Größe">
              <b-input
                type="number"
                :value="license.properties.size"
                :disabled="license.properties.class !== 'pro'"
                @input="onUpdateSeatCount"
              />
            </b-field>

            <b-field
              class="is-flex"
              :message="
                !canPurchaseDVDs
                  ? 'DVDs können nur nach Deutschland geliefert werden.'
                  : ''
              "
            >
              <b-checkbox
                :value="license.properties.dvdCount > 0"
                :disabled="!canPurchaseDVDs"
                @input="onToggleDvd"
              >
                DVD(s) mitbestellen
              </b-checkbox>
              <b-input
                v-if="license.properties.dvdCount > 0"
                ref="dvdCount"
                :disabled="license.properties.class === 'personal'"
                type="number"
                :value="license.properties.dvdCount"
                @input="onUpdateDvdCount"
              />
            </b-field>
          </div>
          <div class="p-1">
            <b-field>
              <template #label>
                Referenz
                <b-tooltip
                  type="is-dark"
                  position="is-left"
                  label="Hier können Sie eine Referenz hinzufügen, um die bestellten Lizenzen einfacher zu unterscheiden."
                >
                  <b-icon
                    size="is-small"
                    icon="info-circle"
                  />
                </b-tooltip>
              </template>
              <b-input
                :value="license.customerReference"
                :placeholder="getCustomerReferencePlaceholder(license.uid)"
                @input="onUpdateCustomerReference"
              />
            </b-field>
          </div>
          <div
            class="is-flex is-justify-content-center"
            style="padding: 0.25rem; padding-top: 2.25rem"
          >
            <b-button
              icon-left="times"
              type="is-danger"
              @click="onRemoveCartItem"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import licenseMixin from '@/mixins/license'
import { mapGetters } from 'vuex'
export default {
  mixins: [licenseMixin],
  props: {
    license: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('assistant', [
      'canPurchaseDVDs',
      'getCustomerReferencePlaceholder'
    ])
  },
  methods: {
    onToggleDvd () {
      const newDvdCount = this.license.properties.dvdCount > 0 ? 0 : 1
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { dvdCount: newDvdCount }
      })
      this.$store.dispatch('assistant/adjustIFrameHeight')
    },
    onUpdateSeatCount (value) {
      const parsedValue = Number.parseInt(value)
      if (isNaN(parsedValue)) {
        return
      }
      const newValue = Math.max(parsedValue, 0)
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { size: newValue }
      })
    },
    onUpdateDvdCount (value) {
      const parsedValue = Number.parseInt(value)

      if (isNaN(parsedValue)) {
        return
      }

      const newValue = Math.max(
        Math.min(parsedValue, this.license.properties.size),
        0
      )
      if (newValue !== parsedValue) {
        this.$refs.dvdCount.newValue = newValue
        this.$refs.dvdCount.$refs.input.value = newValue.toString()
        // this.$refs.dvdCount.$refs.input.blur()
        if (newValue < parsedValue) {
          this.$buefy.toast.open({
            type: 'is-warning',
            message: 'Die maximale DVD-Anzahl ist die Lizenz-Größe'
          })
        }
      }
      this.$store.commit('assistant/updateResellerLicenseProperties', {
        uid: this.license.uid,
        data: { dvdCount: newValue }
      })
    },
    onUpdateCustomerReference (value) {
      this.$store.commit('assistant/updateResellerLicense', {
        uid: this.license.uid,
        data: { customerReference: value }
      })
    },
    onRemoveCartItem () {
      this.$store.commit('assistant/removeResellerLicense', this.license.uid)
      this.$store.dispatch('assistant/adjustIFrameHeight')
    }
  }
}
</script>
