<template>
  <div class="cart-summary">
    <table>
      <thead>
        <tr>
          <th colspan="">
            Artikel
          </th>
          <th colspan="">
            Einzelpreis netto
          </th>
          <th colspan="">
            MwSt.
          </th>
          <th colspan="">
            Anzahl
          </th>
          <th
            colspan=""
            class="has-text-right"
          >
            Betrag netto
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="group of cart.groupedLineItems">
          <tr
            v-if="group.uid !== 'default'"
            :key="group.uid"
            class="group"
          >
            <td
              colspan="5"
              class="customer-reference"
            >
              <span class="customer-reference">Referenz: {{ group.customerReference }}</span>
            </td>
          </tr>
          <tr
            v-for="(lineItem, i) of group.lineItems"
            :key="'group' + group.uid + '-item-' + i"
          >
            <td>
              <h6 class="mb-2 pl-1">
                {{ lineItem.title }}
              </h6>
              <b-field
                v-if="lineItem.itemVolumeDiscount > 0"
                grouped
                multiline
              >
                <div class="control">
                  <b-taglist attached>
                    <b-tag> Einzel-Grundpreis (netto) </b-tag>
                    <b-tag
                      type="is-medium-grey"
                      class="has-text-weight-bold"
                    >
                      {{ lineItem.itemBaseNet | price }}
                    </b-tag>
                  </b-taglist>
                </div>
                <div class="control">
                  <b-taglist attached>
                    <b-tag>
                      Mengenrabatt ({{ relevantDiscountQuantity(lineItem) }}
                      Plätze)
                    </b-tag>
                    <b-tag
                      type="is-medium-grey"
                      class="has-text-weight-bold"
                    >
                      {{ lineItem.itemVolumeDiscount | percent }}
                    </b-tag>
                  </b-taglist>
                </div>
              </b-field>
            </td>
            <td class="nowrap">
              {{ lineItem.itemNet | price }}
            </td>
            <td class="nowrap">
              {{ lineItem.itemVat | price }} ({{ cart.totals.vatRate | vat }})
            </td>
            <td class="nowrap">
              {{ lineItem.quantity }}
            </td>
            <td class="has-text-right nowrap">
              {{ lineItem.net | price }}
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot style="text-align: right">
        <tr>
          <td colspan="4">
            Netto
          </td>
          <td>
            {{ cart.totals.net | price }}
          </td>
        </tr>
        <tr>
          <td colspan="4">
            MwSt. ({{ cart.totals.vatRate | vat }})
          </td>
          <td>
            {{ cart.totals.vat | price }}
          </td>
        </tr>
        <tr>
          <th colspan="4">
            Gesamt
          </th>
          <th>
            {{ cart.totals.gross | price }}
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import licenseMixin from '@/mixins/license'
export default {
  mixins: [licenseMixin],
  computed: {
    // ...mapState('assistant', []),
    ...mapGetters('assistant', [
      'shopProducts',
      'cart',
      'cartTotalProLicenseQuantity',
      'isResellerOrder'
    ])
  },
  methods: {
    relevantDiscountQuantity (lineItem) {
      if (this.isResellerOrder) {
        return lineItem.quantity
      } else {
        return this.cartTotalProLicenseQuantity
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.content table {
  td,
  th {
    @media screen and (max-width: $tablet) {
      font-size: 0.85rem;
    }
  }
  td.customer-reference {
    padding-top: 0;
    padding-bottom: 0;
    span.customer-reference {
      font-weight: bold;
      font-size: 0.75rem;
    }
  }
  td.nowrap {
    white-space: nowrap;
  }
  tbody {
    tr.group td {
      background-color: hsl(0, 0%, 96%);
    }
  }
  tfoot {
    tr:not(:first-child) {
      td,
      th {
        border-width: 0;
      }
    }

    td,
    th {
      padding: 0.1em 0.5em;
    }
  }
}
</style>
