<template lang="pug">
  .upgrade-session-details
    b-tabs
      b-tab-item(label="Eigenschaften")
        .columns
          .column
            widget-panel(
              title="Eigenschaften"
              icon="fa-tasks"
            )
              template(slot="content")
                table.table
                  tbody
                    tr
                      th Art der Session
                      td {{ getPurchaseTypeText(upgradeSession.purchaseType) }}
                    tr
                      th Status
                      td: upgrade-session-status-tag(:status="upgradeSession.status")
                    tr
                      th Erstellt am
                      td
                        date-tag(:date="upgradeSession.createdAt")
                        time-tag(:date="upgradeSession.createdAt")
                    tr
                      th Zuletzt aktualisiert
                      td
                        date-tag(:date="upgradeSession.updatedAt")
                        time-tag(:date="upgradeSession.updatedAt")
                    tr
                      th(data-label="Bestellung") Bestellung
                      td: order-tag(:orderNumber="String(upgradeSession.orderId)")
          .column
            widget-panel(
              title="Details"
              icon="fa-tasks"
            )
              template(slot="content")
                table.table
                  tbody
                    tr
                      th METACOM Version
                      td
                        b-taglist(attached)
                          b-tag {{ upgradeSession.fromVersion }}
                          b-tag: b-icon(icon="arrow-right")
                          b-tag {{ upgradeSession.toVersion }}

            widget-panel(
              title="Angebot"
              icon="fa-file"
              v-if="upgradeSession.offerNumber"
            )
              template(slot="content")
                table.table
                  tbody
                    tr
                      th Nr.
                      td {{ upgradeSession.offerNumber }}
                    tr
                      th Angebotscode
                      td {{ upgradeSession.offerToken }}
                    tr
                      th Angebot
                      td {{ upgradeSession.offerDocument }}
                    tr
                      th E-Mail
                      td {{ upgradeSession.billing.email }}
                    tr
                      th Referenz
                      td {{ upgradeSession.billing.reference }}
                    tr
                      th Hinweis
                      td {{ upgradeSession.billing.note }}
                    tr
                      th Rechnungsadresse
                      td
                        pre {{ upgradeSession.billing | address }}
                    tr
                      th Abweichende Lieferadresse?
                      td {{ upgradeSession.shipToDifferentAddress ? 'Ja' : 'Nein' }}
                    tr
                      th Lieferadresse
                      td
                        pre {{ upgradeSession.shipping | address }}

        .columns
          .column(v-if="upgradeSession.purchaseType === 'extension'")
            h2.subtitle Eigenschaften vor Erweiterung
            license-properties-widget(
              v-if="upgradeSession.beforeExtension && upgradeSession.beforeExtension.properties"
              title="Vorige Eigenschaften"
              :version="upgradeSession.fromVersion"
              :license-class="upgradeSession.beforeExtension.properties.class"
              :type="upgradeSession.beforeExtension.properties.type"
              :size="upgradeSession.beforeExtension.properties.size"
              delivery="download"
              :dvd-count="upgradeSession.beforeExtension.properties.dvdCount"
            )
            license-holder-widget(
              v-if="upgradeSession.beforeExtension && upgradeSession.beforeExtension.holder"
              title="Voriger Lizenznehmer"
              :holder-type="upgradeSession.beforeExtension.holder.holderType"
              :name="upgradeSession.beforeExtension.holder.name"
              :email="upgradeSession.beforeExtension.holder.email"
              :contact="upgradeSession.beforeExtension.holder.contact"
            )
          .column
            h2.subtitle Angaben neue Lizenz
            license-properties-widget(
              v-if="upgradeSession.properties"
              title="Eigenschaften"
              :version="upgradeSession.toVersion"
              :license-class="upgradeSession.properties.class"
              :type="upgradeSession.properties.type"
              :size="upgradeSession.properties.size"
              delivery="download"
              :dvd-count="upgradeSession.properties.dvdCount"
            )
            license-holder-widget(
              v-if="upgradeSession.holder"
              title="Lizenznehmer"
              :holder-type="upgradeSession.holder.holderType"
              :name="upgradeSession.holder.name"
              :email="upgradeSession.holder.email"
              :contact="upgradeSession.holder.contact"
            )
        section(v-if="upgradeSession.beforeExtension")
          h2.subtitle Werte der alten Lizenz vor Erweiterung
          .columns
            .column
              license-properties-widget(
                title="Gewählte Lizenz"
                v-if="upgradeSession.beforeExtension.properties"
                :version="upgradeSession.fromVersion"
                :license-class="upgradeSession.beforeExtension.properties.class"
                :type="upgradeSession.beforeExtension.properties.type"
                :size="upgradeSession.beforeExtension.properties.size"
                delivery="download"
                :dvd-count="upgradeSession.beforeExtension.properties.dvdCount"
              )
            .column
              license-holder-widget(
                v-if="upgradeSession.beforeExtension.holder"
                title="Lizenznehmer"
                :holder-type="upgradeSession.beforeExtension.holder.holderType"
                :name="upgradeSession.beforeExtension.holder.name"
                :email="upgradeSession.beforeExtension.holder.email"
                :contact="upgradeSession.beforeExtension.holder.contact"
              )

        .columns
          .column
            widget-panel(
              title="Ursprüngliche Lizenzen"
              icon="fa-inbox-in"
              :has-content="upgradeSession.originalLicenses && upgradeSession.originalLicenses.length !== 0"
              empty-state-message="Keine Lizenzen"
            )
              template(slot="content")
                table.table: tbody: tr
                  .license-list
                    .license(
                      v-for="licenseId in upgradeSession.originalLicenses"
                    )
                      router-link.is-pulled-right(
                        :to="'/backend/license/' + licenseId"
                        target="_blank"
                        title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
                      )
                        span.icon: i.fas.fa-external-link
                        span {{ licenseId }}

          .column
            widget-panel(
              title="Erzeugte Lizenz"
              icon="fa-inbox-out"
              :has-content="upgradeSession.productLicense != null"
              empty-state-message="Keine Lizenz"
            )
              template(slot="content")
                table.table: tbody: tr
                  .license-list
                    .license
                      router-link.is-pulled-right(
                        :to="'/backend/license/' + upgradeSession.productLicense"
                        target="_blank"
                        title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
                      )
                        span.icon: i.fas.fa-external-link
                        span {{ upgradeSession.productLicense }}
      b-tab-item(label="JSON")
        vue-json-pretty(:data="upgradeSession")
</template>

<script>
import WidgetPanel from '@/components/general/WidgetPanel'
import LicenseIdentifier from '@/components/admin/license/LicenseIdentifier'
import OrderTag from '@/components/general/OrderTag'
import ProgressTag from '@/components/general/ProgressTag'
import DeliveryMixin from '@/mixins/delivery'
import upgradeSessionMixin from '@/mixins/upgrade-session'
import DateTag from '@/components/general/DateTag'
import TimeTag from '@/components/general/TimeTag'
import UpgradeSessionStatusTag from '@/components/admin/upgrade-session/UpgradeSessionStatusTag.vue'
import LicensePropertiesWidget from '@/components/admin/license/LicensePropertiesWidget'
import LicenseHolderWidget from '@/components/admin/license/LicenseHolderWidget'

import VueJsonPretty from 'vue-json-pretty'

export default {
  components: {
    UpgradeSessionStatusTag,
    WidgetPanel,
    LicenseIdentifier,
    OrderTag,
    ProgressTag,
    VueJsonPretty,
    DateTag,
    TimeTag,
    LicenseHolderWidget,
    LicensePropertiesWidget
  },
  mixins: [DeliveryMixin, upgradeSessionMixin],
  props: {
    upgradeSession: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {}
  },
  mounted: function () {
    // this.fetchDeliveries()
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.license-list {
  padding: 1rem;

  .license {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    padding: 0.2em;
    border-radius: 3px;

    .identifier {
      display: inline-flex;
    }
    &.is-active {
      background-color: $medium;
    }
  }
}
</style>
